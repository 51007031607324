import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

export const sendMail = async (formData) => {
    const templateParams = {
        to_name: 'Admin',
        from_name: formData.name,
        from_mail: formData.email,
        from_phone: formData.phone,
        from_path: window.location.href,
        from_location: formData.location,
        reply_to: formData.email,
        subject: formData.subject,
        message: formData.message,
    };
    return emailjs
        .send(
            // 'service_ixh3n1g',
            'service_malla9r',
            'template_x4tjh6u',
            templateParams,
            'Nvyr_z7tlmjvxO4-8'
        )
        .then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
                toast.success('Your request submitted successfully!');
                return true;
            },
            (err) => {
                console.log('FAILED...', err);
                toast.error(
                    'Something went wrong.. Please reach out to info@matrixmantra.lk'
                );
                return false;
            }
        );
};

export const getUserLocation = async () => {
    const ipApiUrl = 'https://ipapi.co/json/';

    const storedLocation = sessionStorage.getItem('userLocationData');

    if (storedLocation) {
        const data = JSON.parse(storedLocation);
        return data;
    }

    try {
        const response = await fetch(ipApiUrl);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        sessionStorage.setItem('userLocationData', JSON.stringify(data));
        return data;
    } catch (error) {
        console.error('Error fetching location:', error.message);
        return;
    }
};
